import './App.css';
import Table from 'react-bootstrap/Table';
import { useState } from "react";

export function GameRecall(props) {
  // We track ess separtely so we can retain the last value for display purposes
  const [ess, setESS] = useState(props.ess);

  const essState = () => {
    if( props.ess > "" && ess !== props.ess ) {
      setESS(props.ess)
    }
    return ess
  }

  return (
    <div>
      <div>
      <span className = "table-title">Game Recall ({essState()})</span>
      </div>
      <Table striped bordered size="sm">
        <thead>
          <tr>
            <th>Location</th>
            <th>Card ID</th>
            <th>Draw</th>
            <th>Spots</th>
            <th>Prize</th>
            <th>Start Credits</th>
            <th>Wager</th>
            <th>Win</th>
            <th>End Credits</th>
          </tr>
        </thead>
        <tbody>
         { props.recall && props.recall.Games ? props.recall.Games.map(function(gr) { 
            if( gr ) {
              return (
                <tr key={gr.SessionID}>
                  <td>{props.recall.FloorLocation}</td>
                  <td>{gr.CardID}</td>
                  <td>{gr.DrawOrder}</td>
                  <td>{gr.SpotsHit}</td>
                  <td>{gr.InterimPrize}</td>
                  <td>{gr.StartCredits}</td>
                  <td>{gr.WagerAmount}</td>
                  <td>{gr.TotalPrize}</td>
                  <td>{gr.EndCredits}</td>
              </tr>    
              )
            }
            return <tr></tr>
          }) : <tr></tr>
        }
        </tbody>  
      </Table>
    </div>
  );
}

/*
	finishJSON(w, map[string]interface{}{
		"ESS":           ess,
		"AssetNumber":   assetNumber,
		"FloorLocation": floorLocation,
		"SiteID":        siteID,
		"Games":         gra,
	})

type GameRecall struct {
	// This is used to tie back to base game
	ESS string // Site ID, ESS

	// These come from game start event
	WagerTime        int64  // Wager Time
	PlayerCardNumber string // Card ID
	WagerAmount      int64  // Wager Amount

	// These will need to come from game end event
	StartCredits int64 // Start Credit
	EndCredits   int64 // End Credit
	TotalPrize   int64 // Total Prize

	// These come from bingo game
	SessionID    string // Session ID
	CardID       int
	DrawOrder    string // Draw Order
	SpotsHit     string // Spots Hit
	EndPrize     bool   // End Prize
	InterimPrize string // Interim Prize

}

*/