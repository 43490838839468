import axios from 'axios'
import { BehaviorSubject } from 'rxjs';
import { NotificationManager } from 'react-notifications';
const FileDownload = require('js-file-download');

//const LocalHostTest = 'http://127.0.0.1:4000' 
const LocalHostTest = 'https://gbb-demo.grabbagbingo.net' 

const serverIntf = (function() {
    var once = false;
    var api
    var retStruct

    // enrollments functions 
    var enroll
    var enable
    var unenroll
    var ignoreEss
    var lookup

    // Version handling
    var versionSubject
    var versionSubjectSubscribe

    // ESS handling
    var ess
    var essSubject
    var essSubjectSubscribe
    var setESS

    // Player handling
    var playersSubject
    var playersSubjectSubscribe
    var playerIDToEss
    var players

    // Games handling
    var gamesSubject
    var gamesSubjectSubscribe
    var games

    // Games handling
    var recallSubject
    var recallSubjectSubscribe
    var recall
    
    // Login handling
    var tok
    var login
    var loginSubject
    var loginSubjectSubscribe
    var get
    var post

    // report handling
    var sessionReport
    var enrollmentReport
    var deckReport
    var auditReport

    const shallowEqual = (object1, object2) => {
        const keys1 = Object.keys(object1);
        const keys2 = Object.keys(object2);
        if (keys1.length !== keys2.length) {
          return false;
        }
        for (let key of keys1) {
          if (object1[key] !== object2[key]) {
            return false;
          }
        }
        return true;
      }


    // test if two arrays are equal given the object coparator
    const arraysEqual = (cur,next) => {
        if( !Array.isArray(next) ) return true // Don't accept next if not array
        if( !Array.isArray(cur) ) return false  // But if cur not array then accept
        if( cur.length !== next.length ) return false // can't be equal if lengths not equal
        for (let i = 0; i < cur.length; i++) {
          if( !shallowEqual(cur[i],next[i]) ) return false
        }
        return true
      }

    // Test two recall obects for equality
    const recallEqual = (cur,next) => {
        if( cur.ESS !== next.ESS ) return false
        if( cur.AssetNumber !== next.AssetNumber ) return false
        if( cur.FloorLocation !== next.FloorLocation ) return false
        if( cur.SiteID !== next.SiteID ) return false
        return arraysEqual(cur.Games,next.Games)
    }
    
    // Initialization login to publich the login state from server
    const initlogin = () => {
        loginSubject = new BehaviorSubject(false)
        loginSubjectSubscribe = (subscriber) => { return loginSubject.subscribe(subscriber) }
        login = (user,pass) => { 
            return api.post('/login', {},{ auth: { username: user, password: pass }})
                .then( (response) => {
                    tok = response.data.Token
                    console.log("login success",user)
                    loginSubject.next(true)
                })
        }
        const request = (url,data,method,isBlob) => {
            let config = {
                url: url,
                data: data,
                method: method,
            }
            if(isBlob) {
                config.responseType = 'blob'
            }
            if(tok) {
                config.headers ={'Authorization': tok }
            }
            return api.request(config).catch( (error) => {
                if (error && error.response) {
                    if(error.response.status === 401 || error.response.status === 403) {
                        if( tok ) {
                            signoff()
                        }
                    }
                }
                throw(error);
              });
        }
        get = (url,isBlob) => { return request(url,null,'get',isBlob) }
        post = (url,data) => { return request(url,data,'post') }

        const timerTask = () => {
            if( tok ) {
                get('/reports/json/players').then( (response) => { 
                    if(response && response.data) {
                        if( !arraysEqual(players,response.data) ) {
                            playersSubject.next(response.data)
                        }                        
                    }
                }).catch(parseWarning)
                get('/reports/json/games').then( (response) => { 
                    if(response && response.data) {
                        if( !arraysEqual(games,response.data) ) {
                            gamesSubject.next(response.data)
                        }                        
                    }
                }).catch(err => {})
                if(ess) {
                    get('/reports/json/game-recall/' + ess).then( (response) => { 
                        if(response && response.data) {
                            if( !recallEqual(recall,response.data)) {
                                recallSubject.next(response.data)
                            }
                        }
                    }).catch(err => {})
                }    
            }
        }
        setInterval(timerTask,1000)

        enroll = (_ess) => { return post('/enrollment/enroll/' + _ess) }
        enable = (_ess) => { return post('/enrollment/enable/' + _ess) }
        unenroll = (_ess) => { return post('/enrollment/unenroll/' + _ess) }    
        ignoreEss = (_ess) => { return post('/enrollment/ignore/' + _ess) }
        
        lookup = () => { return get('/lookup') }

        // reporting
        const report = (url,name) => {
            get(url,true).then( (response) => { 
                FileDownload(response.data, name);
            })
        }
        sessionReport = () => { return report('/reports/csv/sessions','session.csv') }
        enrollmentReport = () => { return report('/reports/csv/games','enrollment.csv') }
        deckReport = () => { return report('/reports/csv/deck','deck.csv') }
        auditReport= () => { return report('/reports/csv/audits','audits.csv') }
    }
    
    // Initialization versionSubject to publich the version from server
    const initVersion = () => {
        versionSubject = new BehaviorSubject('unknown')
        versionSubjectSubscribe = (subscriber) => { return versionSubject.subscribe(subscriber) }

        const getVersion = () => {
            api.get('/version').then( (response) => { 
                versionSubject.next(response.data);
            }).catch(err => {
                setTimeout(getVersion,2000)
            })
        }
        getVersion()
    }

    // Initialization code to startup server interface. This should be called at most once
    // which we ensure by returning a function 
    const init = () => {
        api = axios.create({
            baseURL: (window.location.hostname === 'localhost'
                ? LocalHostTest : 'https://' + window.location.hostname) + "/bingo-server",
            timeout: 30000,
          });

        // Player handling
        playersSubject = new BehaviorSubject([])
        playersSubjectSubscribe = (subscriber) => { return playersSubject.subscribe(subscriber) }
        playersSubject.subscribe((_players) => { players = _players })

        // Games handling
        gamesSubject = new BehaviorSubject([])
        gamesSubjectSubscribe = (subscriber) => { return gamesSubject.subscribe(subscriber) }
        gamesSubject.subscribe((_games) => { games = _games })
        

        // Recall handling
        recallSubject = new BehaviorSubject({})
        recallSubjectSubscribe = (subscriber) => { return recallSubject.subscribe(subscriber) }
        recallSubject.subscribe((_recall) => { recall = _recall })

        // ess handling
        essSubject = new BehaviorSubject('')
        essSubjectSubscribe = (subscriber) => { return essSubject.subscribe(subscriber) }
        essSubject.subscribe((_ess) => { ess = _ess })
        setESS = (_ess) => { 
            if( _ess !== ess ) {
                if( _ess ) recallSubject.next({})
                essSubject.next(_ess)
            }
        }
        playerIDToEss = (id) => { return "::" + id.toString() }
        
        initVersion()
        initlogin() // DO this last so all subjects are defined
    }

    const notify = (typ,title,body,duration) => {
        try {
            title = title || "Unknown"
            body = body || ""
            duration = duration || 750
            NotificationManager[typ](body, title, duration);
        } catch(error) {}    
    }
    const info = (title,body,duration) => { notify('info',title,body,duration) }
    const success = (title,body,duration) => { notify('success',title,body,duration) }
    const warning = (title,body,duration) => { notify('warning',title,body,duration) }
    const error = (title,body,duration) => { notify('error',title,body,duration) }

    const show = (err,nf,duration) => {
        let title = "Unknown Error"
        let body = ""
        try {
            if(err) {
              if( err.response && err.response.statusText ) {
                title = err.response.statusText
              } else {
                if( err.message ) title = err.message 
              }
              if( err.response && err.response.data ) {
                body = err.response.data
              }
            }
            nf(title,body)
        } catch(error) {}    
    }
    const parseError = (err,duration) => { show(err,error,duration) }
    const parseWarning = (err,duration) => { show(err,warning,duration) }

    const signoff = () => {
        tok = null
        loginSubject.next(false)
    }

    const initRetStruct = () => {
        retStruct = {
            SubscribeVersion: versionSubjectSubscribe,
            SubscribeLogin: loginSubjectSubscribe,
            SubscribeESS: essSubjectSubscribe,
            SubscribeGames: gamesSubjectSubscribe,
            SubscribePlayers: playersSubjectSubscribe,
            SubscribeRecall: recallSubjectSubscribe,
            Login: login,
            Get: get,
            Post: post,
            PlayerIDToESS: playerIDToEss,
            SetESS: setESS,
            Lookup: lookup,
            Enroll: enroll,
            Enable: enable,
            UnEnroll: unenroll,
            Ignore: ignoreEss,
            ParseError: parseError,
            ParseWarning: parseWarning,
            Info: info,
            Success: success,
            Warning: warning,
            Error: error,
            SignOff: signoff,
            SessionReport: sessionReport,
            EnrollmentReport: enrollmentReport,
            DeckReport: deckReport,
            AuditReport: auditReport,
        } 
    }

    return function() {
        if (!once) {
            once = true;
            init()
            initRetStruct()
        }
        return retStruct
    };

})();

export const ServerIntf = serverIntf()

