import './Login.css';
import React,{useState} from 'react' 
import { ServerIntf } from './ServerIntf'


export function Login() { 
	const [username,setUsername]=useState(""); 
	const [password,setPassword]=useState(""); 
	const submitThis=()=>{
    ServerIntf.Login(username,password).catch( ServerIntf.ParseWarning )
  }
  
  return(
    <div className="container">
      <div className="form-box">
        <div className="header-form ">
          <span className="login-header text-center">Welcome to Grab Bag Bingo Casino Management Tool</span>
        </div>
        <div className="header-form">
          <h4 className="text-primary text-center"><i className="fa fa-user-circle" style={{fontSize:"110px"}}></i></h4>
          <div className="image"></div>
        </div>
        <div className="body-form">
          <form>
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text"><i className="fa fa-user"></i></span>
              </div>
              <input type="text" className="form-control" placeholder="Username" value={username} onChange={(e)=>setUsername(e.target.value)} />
            </div>
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text"><i className="fa fa-lock"></i></span>
              </div>
              <input type="password" className="form-control" placeholder="Password" value={password} onChange={(e)=>setPassword(e.target.value)} />
            </div>
            <button type="button" className="btn btn-secondary btn-block"  
              disabled={username === "" || password === ""}
              onClick={() => submitThis()}>
                Login
            </button>
          </form>
        </div>
      </div>
    </div>   
  )
}
