import './App.css';
import Table from 'react-bootstrap/Table';
import { ServerIntf } from './ServerIntf'

export function Games(props) {
  
  const essSelected = (ess) => {
    console.log("Games.ess",ess)
    ServerIntf.SetESS(ess)
  }

  const enrollChange = (sw, egm) => {
    let ef = ServerIntf.Enroll
    if(sw === 0) {
      if( egm.EnrolledState > 0 )  ef = ServerIntf.UnEnroll
    } else {
      if( egm.EnrolledState < 2 )  ef = ServerIntf.Enable
    }
    ef(egm.ESS).then((response) => {
      egm.EnrolledState = response.data
    }).catch( (err) => {
      console.log("Games.enrollChange",err)
    })
  }

  const ignoreEGM = (egm) => {
    ServerIntf.Ignore(egm.ESS)
  }


  return (
    <div className='.container-fluid'>
      <div>
        <span className = "table-title">EGM Players</span>
      </div>
      <Table striped bordered size="sm">
        <thead>
          <tr>
            <th>Location</th>
            <th>Foundation ID</th>
            <th>Wins</th>
            <th>Enrolled?</th>
            <th>Enabled?</th>
            <th>Recall?</th>
            <th>Remove</th>
          </tr>
        </thead>
        <tbody>
          {props.games.map(function(egm){ 
            if( egm  && egm.ESS && egm.IgnoredState == 0 ) {
              let selected = egm.ESS === props.ess
              let enrolled = egm.EnrolledState > 0
              let enabled = egm.EnrolledState === 2            
              return (
                <tr key={egm.ESS}>
                  <td>{egm.FloorLocation}</td>
                  <td>{egm.ESS}</td>
                  <td>{egm.EndPrizeWins}</td>
                  <td>
                    <div className="form-check form-switch">
                      <input className="form-check-input" type="checkbox" role="switch" 
                        checked={enrolled} onChange={() => enrollChange(0, egm)}/>
                    </div>
                  </td>
                  <td>
                    <div className="form-check form-switch">
                      <input className="form-check-input" type="checkbox" role="switch" 
                        checked={enabled} onChange={() => enrollChange(1, egm)}/>
                    </div>
                  </td>
                  <td>
                    <div className="form-check form-switch">
                      <input className="form-check-input" type="checkbox" role="switch" 
                        checked={selected} onChange={() => essSelected(selected ? '' : egm.ESS)}/>
                    </div>
                  </td>
                  <td>
                    <div className=''>
                      <input className="" type="button" value="Remove" onClick={() => ignoreEGM(egm)}  />
                    </div>
                  </td>
              </tr>    
              )
            }
            return ""
          })}
        </tbody>  
      </Table>
    </div>
  );
}

/*
type Game struct {
	// These identify the game
	ESS            string // Site ID, ESS
	AssetNumber    int
	FloorLocation  string // Location field
	EnrolledState  EnrolledState
	EnrolledTime   int64
	EnabledTime    int64
	UnEnrolledTime int64

	Credits      int64 // current credits
	EndPrizeWins int64 // Number of times this game won end prize
	LastFive     []*GameRecall
}
*/